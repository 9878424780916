import React, { Component } from "react";
import { GetImages } from "../data/projects";

function imagesLoaded(img) {
  return img.complete;
}

function imagesLoadedComplete() {
  let images = document.getElementsByTagName("img");
  for (const img of images) {
    return img.complete;
  }
}

export default class AnimationOpacity extends Component {
  constructor(props) {
    super(props);

    this.animation = () =>
      setTimeout(() => {
        this.animate();
      }, 3000);

    this.endEventAnimation = (element, classCss, callBack) => {
      element.classList.remove(classCss);
    };

    this.state = {
      index: 0,
      total: 12,
      loading: true,
      data: [],
    };

    this.listRef = React.createRef();
    this.animate = this.animate.bind(this);
  }
  componentDidMount() {
    // let element = this.listRef.current.getElementsByClassName("slide_item")[0];
    // element.classList.add("show");
    GetImages(27).then((r) => {
      if (r.status === 200) {
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 3000);
        this.setState({
          data: r.data.data,
        });
      }
    });
    setTimeout(() => {
      this.animation();
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.animation);
  }

  removeClass(element, classList) {
    classList.forEach((item) => {
      element.classList.remove(item);
    });
  }

  addClass(element, classList) {
    classList.forEach((item) => {
      element.classList.add(item);
    });
  }

  onLoad(e, index) {
    if (imagesLoaded(e.target)) {
      let images = document.getElementsByClassName("slide_item");
      if (images.length > 0) {
        let item = images[index];
        let img = window.$(item).find("img");
        window
          .$(item)
          .css("background-image", "url('" + window.$(img).attr("src") + "'");
        window.$(img).remove();
      }
    }

    if (imagesLoadedComplete()) {
      this.setState({
        loading: false,
        index: 0,
      });
    }
  }

  animate() {
    let index = this.state.index;
    if (
      this.listRef === undefined ||
      this.listRef === null ||
      this.listRef.current === undefined ||
      this.listRef.current === null
    )
      return;
    let elements = this.listRef.current.getElementsByClassName("slide_item");
    index = index + 1;
    let before = index - 1;
    let current = index;
    if (index + 1 > this.state.total) {
      index = 0;
      before = this.state.total - 1;
      current = 0;
    }
    if (index === 1) {
      this.removeClass(elements[this.state.total - 1], ["show"]);
    }
    if (elements[before - 1]) {
      this.removeClass(elements[before - 1], ["show"]);
    }
    this.removeClass(elements[before], ["active"]);
    let element = elements[current];

    let endAnimation = () => {
      element.removeEventListener("animationend", endAnimation);
      this.animation();
      this.setState({ index: index });
    };

    element.addEventListener("animationend", endAnimation);
    this.addClass(element, ["active", "show"]);
  }
  render() {
    return (
      <div className="slide_show" ref={this.listRef}>
        <div className={"opacity" + (this.state.loading ? "" : " hide")}></div>
        {this.state.data &&
          this.state.data.map((item, index) => {
            return (
              <div key={index} className={"slide_item " + (index === 0 ? "show" : "")} style={{"backgroundImage": process.env.REACT_APP_API + "/images/projects/" + item.images}}>
                <img
                  src={ process.env.REACT_APP_API + "/images/projects/" + item.images}
                  alt={"slide" + index + 1}
                  onLoad={(e) => this.onLoad(e, index)}
                  style={{"visibility": "hidden"}}
                />
              </div>
            );
          })}
      </div>
    );
  }
}
