import React, { Component } from "react";
import Sidebar from "./menu";
import Footer from "./footer";

/* Logos */
import WhiteLogo from "../images/CD-Icons-02-white.png";
export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.listRef = React.createRef();
    this.menuRef = React.createRef();
    this.onActive = this.onActive.bind(this);
    this.noActive = this.noActive.bind(this);
  }
  onActive() {
    document.body.classList.toggle("hidden");
    // Correct
    this.setState((state) => ({
      active: !state.active,
    }));
  }
  noActive() {
    document.body.classList.remove("hidden");
    // Correct
    this.setState((state) => ({
      active: false,
    }));
  }
  componentWillUnmount() {
    document.body.classList.toggle("hidden");
  }
  render() {
    return (
      <div className={"app " + (this.state.active ? "active" : "")}>
        {this.state.active && (
          <div
            className={
              "app_opacity " + (this.state.active ? "active" : "noactive")
            }
            onClick={this.noActive}
          ></div>
        )}
        <Sidebar active={this.state.active} onClick={this.noActive} />
        {/* <div
          className={"app_menu " + (this.state.active ? "active" : "")}
          onClick={this.onActive}
        >
          <span>
            <img src={WhiteLogo} alt="Logo" />
          </span>
        </div> */}
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
