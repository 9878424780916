import React, { Component } from "react";
import $ from "jquery";
import { a } from "react-router-dom";
import SlideShow from "../components/animationOpacity";
import Logo from "../images/CD-Icons-04-white.png";
import DownArrow from "../images/CD-Icons-03-white.png";
import Video from "../components/video";
import { Menu } from "../data/furniture";
import { MenuProject } from "../data/projects";
import { Section } from "../data/section";
import Close from "../images/Icons-Web-Final-08-black.png";

function isOnScreen(elem) {
  // if the element doesn't exist, abort
  if (elem.length === 0) {
    return;
  }
  var $window = $(window);
  var viewport_top = $window.scrollTop();
  var viewport_height = $window.height();
  var viewport_bottom = viewport_top + viewport_height;
  var $elem = $(elem);
  var top = $elem.offset().top;
  var height = $elem.height();
  var bottom = top + height;

  return (
    (top >= viewport_top && top < viewport_bottom) ||
    (bottom > viewport_top && bottom <= viewport_bottom) ||
    (height > viewport_height &&
      top <= viewport_top &&
      bottom >= viewport_bottom)
  );
}

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      furniture: [],
      aviation: [],
      marine: [],
      residential: [],
      commercial: [],
      area: this.props.match.params.area,
      section: {
        furniture: {
          title: "",
          images: "",
        },
        marine: {
          title: "",
          images: "",
        },
        aviation: {
          title: "",
          images: "",
        },
        commercial: {
          title: "",
          images: "",
        },
        residential: {
          title: "",
          images: "",
        },
        studio: {
          title: "",
          images: "",
        },
        quote: {
          title: "",
          text: "",
        },
      },
    };
    this.getMenu = this.getMenu.bind(this);
    this.getSection = this.getSection.bind(this);
  }
  componentDidMount() {
    this.setHeight();
    window.addEventListener("resize", () => {
      this.setHeight();
      this.onScroll();
    });
    $(".app").scroll(() => {
      this.onScroll();
    });
    this.onScroll();
    this.getMenu();
    this.getSection();
    if (this.state.area) {
      if (this.state.area === "studio") return;
      if (this.state.area === "furniture") {
        $("#" + this.state.area)
          .find(".home_section_content")
          .addClass("active");
      } else {
        $("#" + this.state.area).addClass("active");
      }

      if (
        this.state.area != "marine" &&
        this.state.area != "commercial" &&
        this.state.area != "residential"
      ) {
        window.location.href = "https://shop.casadiousa.com";
      }
    } else {
      if (this.state.area === "" || this.state.area == undefined) {
        window.location.href = "https://shop.casadiousa.com";
      }
    }
  }
  setHeight() {
    $(".two_slide .home_section_content").each((index, element) => {
      $(element).height($(element).width());
    });
  }
  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }
  getMenu() {
    // get furniture
    Menu().then((r) => {
      if (r.status === 200) this.setState({ furniture: r.data });
    });
    // Marine
    MenuProject(1).then((r) => {
      if (r.status === 200) this.setState({ marine: r.data });
    });
    // Aviation
    MenuProject(2).then((r) => {
      if (r.status === 200) this.setState({ aviation: r.data });
    });
    // Commercial
    MenuProject(3).then((r) => {
      if (r.status === 200) this.setState({ commercial: r.data });
    });
    // Residential
    MenuProject(4).then((r) => {
      if (r.status === 200) this.setState({ residential: r.data });
    });
  }
  getSection() {
    // get furniture
    Section(1).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.section.furniture.title = r.data.title;
        state.section.furniture.images = r.data.images;
        this.setState(state);
      }
    });

    // Marine
    Section(2).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.section.marine.title = r.data.title;
        state.section.marine.images = r.data.images;
        this.setState(state);
      }
    });

    // Aviation
    Section(3).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.section.aviation.title = r.data.title;
        state.section.aviation.images = r.data.images;
        this.setState(state);
      }
    });

    // Commercial
    Section(4).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.section.commercial.title = r.data.title;
        state.section.commercial.images = r.data.images;
        this.setState(state);
      }
    });

    // Residential
    Section(5).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.section.residential.title = r.data.title;
        state.section.residential.images = r.data.images;
        this.setState(state);
      }
    });

    // Studio
    Section(6).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.section.studio.title = r.data.title;
        state.section.studio.images = r.data.images;
        this.setState(state);
      }
    });

    // Quote
    Section(7).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.section.quote.title = r.data.title;
        state.section.quote.text = r.data.text;
        this.setState(state);
      }
    });
  }
  onScroll() {
    let elements = $(".home_section_content");
    elements.each((index, item) => {
      if (isOnScreen(item)) {
        $(item).addClass("animate_top");
      } else {
        // $(item).removeClass("animate_top");
      }
    });
  }
  render() {
    return (
      <div className={"home"}>
        <div className="home_header">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <SlideShow />
          <div className="arrow">
            <img src={DownArrow} alt="logo" />
          </div>
          <div className="city">
            <span>MIAMI | LONDON</span>
          </div>
        </div>
        <div className="home_section pr-0 mr-0 pl-0 ml-0 first">
          <div className="home_section_content content_video">
            <Video />
          </div>
        </div>

        <div className="home_section" id="furniture">
          <div
            data-aos="fade-up"
            className="home_section_content furniture_h"
            style={{
              backgroundImage:
                "url('" +
                process.env.REACT_APP_API +
                "/images/sections/" +
                this.state.section.furniture.images +
                "')",
            }}
            onClick={(e) => {
              $(e.target).addClass("active");
              //$(e.target).find(".home_section_content_menu").addClass("active");
            }}
          >
            <div className="home_section_content_menu">
              <div
                className="home_section_content_menu_close"
                onClick={() => {
                  window.open("https://casadiousa.com");
                }}
              >
                <img src={Close} alt="close" />
              </div>
              <ul>
                {this.state.furniture &&
                  this.state.furniture.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={"/furniture/" + item.id}>{item.category}</a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <h1 style={{ textTransform: "uppercase", color: "#000" }}>
              {this.state.section.furniture.title}
            </h1>
          </div>
        </div>

        <div className="home_section two_slide">
          <div
            id="marine"
            className="home_section_content"
            onClick={(e) => {
              $(e.target).addClass("active");
            }}
          >
            <div className="home_section_content_menu">
              <div
                className="home_section_content_menu_close"
                onClick={() => {
                  window.open("https://shop.casadiousa.com", "_self");
                }}
              >
                <img src={Close} alt="close" />
              </div>
              <ul>
                {this.state.marine &&
                  this.state.marine.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={"/projects/marine/" + item.id}>{item.title}</a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <h1 style={{ textTransform: "uppercase", color: "#000" }}>
              {this.state.section.marine.title}
            </h1>
          </div>

          <div
            id="aviation"
            className="home_section_content"
            style={{
              backgroundImage:
                "url('" +
                process.env.REACT_APP_API +
                "/images/sections/" +
                this.state.section.aviation.images +
                "')",
            }}
            onClick={(e) => {
              $(e.target).addClass("active");
              //$(e.target).find(".home_section_content_menu").addClass("active");
            }}
          >
            <div className="home_section_content_menu">
              <div
                className="home_section_content_menu_close"
                onClick={() => {
                  window.open("https://shop.casadiousa.com", "_self");
                }}
              >
                <img src={Close} alt="close" />
              </div>
              <ul>
                {this.state.aviation &&
                  this.state.aviation.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={"/projects/aviation/" + item.id}>
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <h1 style={{ textTransform: "uppercase",  color: "#000" }}>
              {this.state.section.aviation.title}
            </h1>
          </div>
        </div>

        <div className="home_section two_slide">
          <div
            id="commercial"
            className="home_section_content"
            style={{
              backgroundImage:
                "url('" +
                process.env.REACT_APP_API +
                "/images/sections/" +
                this.state.section.commercial.images +
                "')",
            }}
            onClick={(e) => {
              $(e.target).addClass("active");
            }}
          >
            <div className="home_section_content_menu">
              <div
                className="home_section_content_menu_close"
                onClick={() => {
                  window.open("https://shop.casadiousa.com", "_self");
                }}
              >
                <img src={Close} alt="close" />
              </div>
              <ul>
                {this.state.commercial &&
                  this.state.commercial.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={"/projects/commercial/" + item.id}>
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <h1 style={{ textTransform: "uppercase",  color: "#000" }}>
              {this.state.section.commercial.title}
            </h1>
          </div>

          <div
            id="residential"
            className="home_section_content"
            style={{
              backgroundImage:
                "url('" +
                process.env.REACT_APP_API +
                "/images/sections/" +
                this.state.section.residential.images +
                "')",
            }}
            onClick={(e) => {
              $(e.target).addClass("active");
            }}
          >
            <div className="home_section_content_menu">
              <div
                className="home_section_content_menu_close"
                onClick={() => {
                  window.open("https://shop.casadiousa.com", "_self");
                }}
              >
                <img src={Close} alt="close" />
              </div>
              <ul>
                {this.state.residential &&
                  this.state.residential.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={"/projects/residential/" + item.id}>
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <h1 style={{ textTransform: "uppercase",  color: "#000" }}>
              {this.state.section.residential.title}
            </h1>
          </div>
        </div>

        <div
          className="home_section"
          id="studio"
          onClick={() => {
            window.location.href = "/projects/studio/24";
          }}
        >
          <div
            className="home_section_content cover studio"
            style={{
              backgroundImage:
                "url('" +
                process.env.REACT_APP_API +
                "/images/sections/" +
                this.state.section.studio.images +
                "')",
            }}
          >
            <h1 style={{ textTransform: "uppercase", color: "#000" }}>
              {this.state.section.studio.title}
            </h1>
          </div>
        </div>

        <div className="home_section m_h">
          <div
            className="home_section_content middle"
            style={{ backgroundColor: "black" }}
          >
            <h2
              dangerouslySetInnerHTML={{
                __html: this.state.section.quote.text.replace(/\n/g, "<br />"),
              }}
            ></h2>
            <span>{this.state.section.quote.title}</span>
          </div>
        </div>
      </div>
    );
  }
}
