import React, {Component} from "react";
import {Detailt} from "../data/furniture";
import Carousel from "../components/productCarousel";
import Loading from "../components/loading";
import Close from "../images/300/Icons-Web-Final-01-White.png";
import CloseBlack from "../images/300/Icons-Web-Final-01.png";
import Footer from '../components/footer';


export default class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            return: this.props.match.params.return,
            furniture: this.props.match.params.furniture,
            data: null,
            title: "",
            loading: true,
            url: "",
            detailt: null
        };
    }

    componentDidMount() {
        window.$(".app").css("background-color", "white");
        Detailt(this.state.id).then((r) => {
            if (r.status === 200) {
                this.setState({
                    loading: false,
                    url: r.data.url,
                    data: r.data.data,
                    title: r.data.title,
                    detailt: r.data.detailt
                });
            }
        });
    }

    render() {
        if (this.state.loading === true) return null;
        return (
            <div className="detailt show">
                <div className="detailt_exit" onClick={() => window.open('/furniture/' + this.state.furniture, '_self')}>
                    <img src={this.state.detailt.is_black === "1" ? CloseBlack : Close} alt="Close" onClick={() => window.open('/furniture/' + this.state.furniture, '_self')}/>
                </div>
                <div className="detailt_content">
                    <div className="detail-full-screen">
                        {
                            this.state.data && this.state.data.map((item, index) => {
                                let images_path = this.state.url + item.images;
                                if (index === 0) {
                                    return (
                                        <div className="header-full-screen" key={index}
                                             style={{backgroundImage: `url(${images_path})`, backgroundColor: (this.state.detailt.is_black === "1" ? "white" : "black") }}>
                                            <div  className="header-full-screen_header">
                                                <h1 style={{color: (this.state.detailt.is_black === "1" ? "black" : "white") }}>{this.state.title && this.state.title}</h1>
                                            </div>
                                            <div className="header-full-screen_action">
                                                <div style={{color: (this.state.detailt.is_black === "1" ? "black" : "white") }} className="action">MIAMI | LONDON</div>
                                            </div>
                                        </div>
                                    )
                                }
                                if (index === 1) {
                                    return (
                                        <div key={index} className="item-full-screen first">
                                            <div className="item-full-screen-item"
                                                 style={{backgroundImage: `url(${images_path})`,
                                                     backgroundColor: (this.state.detailt.is_black === "1" ? "white" : "black") }}/>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index} className="item-full-screen">
                                            <div className="item-full-screen-item"
                                                 style={{backgroundImage: `url(${images_path})`, backgroundColor: (this.state.detailt.is_black === "1" ? "white" : "black") }}/>
                                        </div>
                                    )
                                }
                            })
                        }
                        <div className="item-full-screen h-auto">
                            <div className={"item-full-screen-size"}>
                              <h3>{this.state.title && this.state.title}</h3>
                                <div className={"size-info"}>
                                    <table>
                                      <tbody>
                                      <tr>
                                          <td valign="top">
                                              <div className={"separate_size"}>
                                                  <span style={{width: "80px"}}>DIMENSIONS</span><span style={{width: "20px"}}>&nbsp;|&nbsp;</span>
                                                  <span>H&nbsp;{this.state.detailt && Math.round(this.state.detailt.size_y)}in&nbsp;</span>
                                                  <span>W&nbsp;{this.state.detailt && Math.round(this.state.detailt.size_x)}in&nbsp;</span>
                                                  <span>D&nbsp;{this.state.detailt && Math.round(this.state.detailt.size_z)}in&nbsp;</span>
                                              </div>
                                              <div className={"separate_size"}>
                                                  <span style={{width: "100px"}}/>
                                                  <span>H&nbsp;{this.state.detailt && Math.round(this.state.detailt.size_y_cm)}cm&nbsp;</span>
                                                  <span>W&nbsp;{this.state.detailt && Math.round(this.state.detailt.size_x_cm)}cm&nbsp;</span>
                                                  <span>D&nbsp;{this.state.detailt && Math.round(this.state.detailt.size_z_cm)}cm&nbsp;</span>
                                              </div>
                                              <div className={"separate_size"}>
                                                  <span style={{width: "80px"}}>C.O.M</span><span style={{width: "20px"}}>&nbsp;|&nbsp;</span>
                                                  <span>{this.state.detailt && Math.round(this.state.detailt.com)}&nbsp;yards&nbsp;</span>
                                              </div>
                                              <div className={"separate_size"}>
                                                  <span style={{width: "80px"}}>{this.state.detailt.specsheet && <a style={{textDecoration: "none", color: "inherit" }} href={process.env.REACT_APP_API  + "/specsheet/" + this.state.detailt.specsheet} target="_blank">SPEC SHEET</a>}</span><span style={{width: "20px"}}>&nbsp;&nbsp;</span>
                                              </div>
                                          </td>
                                          <td align="right">
                                              <div className={"title_line"}>
                                                  <span>{this.state.detailt && this.state.detailt.title1}</span>
                                              </div>
                                              <div className={"title_line"}>
                                                  <span>{this.state.detailt && this.state.detailt.title2}</span>
                                              </div>
                                              <div className={"title_line"}>
                                                  <span>{this.state.detailt && this.state.detailt.title3}</span>
                                              </div>
                                          </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                </div>
                                <div className={"size-info"}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}