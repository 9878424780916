import React, { Component } from "react";
import ItemMenu from "./itemMenu";
import Logo from "../images/CD-Icons-02-white.png";
export default class Menu extends Component {
  render() {
    return (
      <div className={"menu " + (this.props.active ? "active" : "")}>
        <ItemMenu to="/home" label="Home" />
        <div className="mini-logo">
          <img src={Logo} alt="logo" />
        </div>
        <ItemMenu
          to="/home/furniture"
          label="Furniture"
          onClick={this.props.onClick}
        />
        <ItemMenu
          to="/home/marine"
          label="Marine"
          onClick={this.props.onClick}
        />
         <ItemMenu
          to="/home/aviation"
          label="On the Air"
          onClick={this.props.onClick}
        />
        <ItemMenu
          to="/home/commercial"
          label="Commercial"
          onClick={this.props.onClick}
        />
        <ItemMenu
          to="/home/residential"
          label="Residential"
          onClick={this.props.onClick}
        />
        <ItemMenu
          to="/projects/studio/24"
          label="Studio"
          onClick={this.props.onClick}
        />
        <div className="mini-logo">
          <img src={Logo} alt="logo" />
        </div>
        <ItemMenu to="/about" label="About Us" onClick={this.props.onClick} />
        <ItemMenu
          to="/contact"
          label="Contact Us"
          onClick={this.props.onClick}
        />
      </div>
    );
  }
}
